<script context="module" lang="ts">
  export const productIcons: Record<string, IconName> = {
    talk: "product-brave-talk",
    search: "brave-icon-search",
    leo: "product-brave-leo",
    vpn: "product-vpn",
  };
</script>

<script lang="ts">
  import type { IconName } from "@brave/leo/icons/meta";
  import Icon from "@brave/leo/src/components/icon/icon.svelte";

  export let name: string;
  export let size: "default" | "small" = "default";
</script>

<div class="logo" data-product={name} data-size={size}>
  <Icon name={productIcons[name]} />
</div>

<style>
  .logo {
    --logo-background: theme("colors.primitive.primary.40");
    --logo-radius: calc(var(--logo-size) * 0.2);
    --leo-icon-size: 100%;
    --logo-size: var(--product-logo-size, var(--logo-size-internal));
    width: var(--logo-size);
    height: var(--logo-size);
    color: #ffffff;
    background: var(--logo-background);
    border-radius: var(--logo-radius);
    box-shadow:
      0px 1px 0px 0px rgba(255, 255, 255, 0.25) inset,
      0px -1px 0px 0px rgba(0, 0, 0, 0.15) inset;
    padding: calc(var(--logo-size) * 0.22);
    position: relative;
    flex-shrink: 0;
  }

  .logo::after {
    content: "";
    width: var(--logo-size);
    height: var(--logo-size);
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
    background-repeat: no-repeat;
    background-position-y: calc(-1 * (var(--logo-size) / 1.7));
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: var(--logo-radius);
  }

  [data-size="default"] {
    --logo-size-internal: 72px;
  }

  [data-size="small"] {
    --logo-size-internal: 40px;
  }
</style>
